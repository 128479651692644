import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Jobs" description="Winery Jobs at Archibald James Wine & Ciderworks" mdxType="SEO" />
    <article className="mx-auto prose prose-xl">
      <h1 {...{
        "id": "jobs"
      }}>{`Jobs`}</h1>
      <p>{`Now hiring:`}</p>
      <h2 {...{
        "id": "tasting-room-associate"
      }}>{`Tasting Room Associate`}</h2>
      <p><strong parentName="p">{`Archibald James Wine & Ciderworks`}</strong>{` is a boutique producer of handcrafted beverages. We’re innovating on the cutting-edge of our industry, creating delicious products that surprise and delight our customers. `}</p>
      <p>{`We’re seeking a part-time brand ambassador to work our tasting room in Leavenworth. You’ll introduce guests to our wonderful wines and ciders, operate the store efficiently, and provide a stellar customer experience.`}</p>
      <p><strong parentName="p">{`About you:`}</strong></p>
      <p>{`·   You’re passionate about the wine industry. If you don’t have industry experience, you’re eager to learn (we’re happy to teach you!).`}</p>
      <p>{`·   You’re good with people, able to interact with guests and provide first-class customer service, even in stressful situations.`}</p>
      <p>{`·   You’re age 21+ and able to maintain a WA food worker card and alcohol server permit. You understand how to sell products legally in a regulated environment.`}</p>
      <p>{`·   You’re able to lift heavy objects, work in crowded/confined spaces, remain mobile throughout operating hours.`}</p>
      <p><strong parentName="p">{`The job:`}</strong></p>
      <p>{`·   Represent the Archibald James brand. We’re really proud of what we’re building and the products we’re making, and we need someone to help us sell it with enthusiasm.`}</p>
      <p>{`·   Provide first-class customer service. This is high-touch hospitality, so friendly and positive guest interaction is imperative.`}</p>
      <p>{`·   Drive conversion to our club. Most of our business is direct-to-consumer, and the tasting room is where they sign up.`}</p>
      <p>{`·   Manage the shop, keeping it clean and in shipshape, tracking inventory and fulfilling orders.`}</p>
      <p>{`·   Perform additional duties as assigned (if you’re interested in the industry, there’s room to grow here!)`}</p>
      <p><strong parentName="p">{`Hours:`}</strong></p>
      <p>{`We’re currently looking for someone to work afternoons/evenings Friday - Sunday.`}</p>
      <p><strong parentName="p">{`Wage:`}</strong></p>
      <p>{`Base $15/hr + tips + bonus. More if you have wine industry knowledge or experience.`}</p>
      <p><strong parentName="p">{`To apply:`}</strong></p>
      <p>{`Send an email to bryan at archibaldjames.com. Attach a resume and a brief response to the following questions (a few sentences is fine):`}</p>
      <blockquote>
        <ul parentName="blockquote">
          <li parentName="ul">{`What’s your proudest accomplishment in a work/school context?`}</li>
          <li parentName="ul">{`Describe a challenge you overcame in your work, a conflict you resolved, or how you dealt with a difficult customer.`}</li>
        </ul>
      </blockquote>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      