import React from "react"
import Layout from "../layouts/Layout"

export default function MdxLayout({ children }) {
  return (
    <Layout>
      <div className="mx-auto prose prose-xl mt-8">{children}</div>
    </Layout>
  )
}
